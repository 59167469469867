<template>
  <div
    class="result-row"
    :class="{
      'second-bg': result.isEven,
      'current-user': result.isCurrentUser,
    }"
  >
    <div class="member-position">{{ result.rank }}</div>
    <div class="member-info" @click="openPublicProfile">
      <div
        class="member-avatar"
        :class="{ 'spartan-plus': result.spartan_plus }"
      >
        <img v-if="result.avatar" :src="result.avatar" alt="" />
        <default-avatar v-if="!result.avatar" :size="28" :font-size="12">{{
          result.avatarLetters
        }}</default-avatar>
        <div class="is-captain" v-if="result.isCaptain || result.isOwner">
          <i class="material-icons" v-if="result.isCaptain"> star_rate </i>
          <v-icon v-if="result.isOwner">mdi-crown</v-icon>
        </div>
      </div>
      <div class="member-name">
        <span>{{ result.first_name }} {{ result.lastName }}.</span>
        <span class="spartan-plus-subline" v-if="result.spartan_plus"
          >spartan EDGE</span
        >
      </div>
    </div>
    <div class="result-category">
      <span>{{ result.stats.rank_overall || "--" }}</span>
      <span>/{{ result.stats.count_overall || "--" }}</span>
    </div>
    <div class="result-gender">
      <span>{{ result.stats.rank_gender || "--" }}</span>
      <span>/{{ result.stats.count_gender || "--" }}</span>
    </div>
    <div class="result-time">{{ result.time }}</div>
  </div>
</template>
<script>
export default {
  props: ["result"],
  components: {
    "default-avatar": () => import("@/components/DefaultAvatar.vue"),
  },
  methods: {
    openPublicProfile() {
      this.$emit("open-profile");
    },
  },
};
</script>
<style lang="scss" scoped>
.result-row {
  height: 70px;
  display: grid;
  grid-template-columns:
    34px minmax(max-content, auto) minmax(auto, max-content)
    110px 104px;
  align-items: center;
  padding-left: 20px;
  padding-right: 45px;
  background-color: #121212;
  font-size: 12px;
  line-height: 15px;

  .member-position {
    text-align: end;
    font-weight: bold;
  }
  .member-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;

    @include cursorPointer;
    .member-avatar {
      margin-right: 26px;
      position: relative;
      img {
        height: 28px;
        width: 28px;
        border-radius: 50%;
      }
      .is-captain {
        position: absolute;
        top: 0;
        right: -2px;
        z-index: 10;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        background-color: #cf1019;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        i {
          font-size: 8px;
        }
      }
    }
    .spartan-plus {
      position: relative;
    }
    .spartan-plus::after {
      content: " ";
      position: absolute;
      left: 0;
      top: 0;
      z-index: 5;
      border-radius: 50%;
      border: 2px solid #cf1019;
      height: 28px;
      width: 28px;
    }
    .member-name {
      display: flex;
      flex-direction: column;
      text-transform: capitalize;
      font-weight: bold;
      .spartan-plus-subline {
        color: #cf1019;
        font-size: 9px;
        line-height: 11px;
        font-weight: 600;
      }
    }
  }
  .result-category {
    text-align: end;
  }
  .result-gender {
    text-align: end;
  }
  .result-time {
    text-align: end;
    font-weight: bold;
  }
}
.second-bg {
  background-color: #000000;
}
.current-user {
  background-color: #cf102d;
  .member-info {
    .member-avatar {
      .is-captain {
        background-color: #ffffff;
        i {
          color: #cf102d;
        }
      }
    }
    .member-name {
      .spartan-plus-subline {
        color: #ffffff;
      }
    }
    .spartan-plus::after {
      border: 2px solid #ffffff;
    }
  }
}
</style>